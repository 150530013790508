@import "../../assets/constant/color";

.select-search {
    position: relative;
    font-family: "Roboto-Regular";
    box-sizing: border-box;
    width: 190px;
  }
  
  .select-search *,
  .select-search *::after,
  .select-search *::before {
    box-sizing: inherit;
  }
  
  .select-search__value {
    position: relative;
    z-index: 1;
  }
  
  .select-search__value::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: calc(50% - 7px);
    left: 10px;
    width: 20px;
    height: 20px;
  }
  
  .select-search__input {
    display: block;
    height: 36px;
    width: 100%;
    padding: 0 16px 0px 35px;
    background: $color-purewhite;
    border: 1px solid $color-puregray;
    border-radius: 3px;
    outline: none;
    font-family: "Roboto-Regular";
    font-size: 12px;
    text-align: left;
    text-overflow: ellipsis;
    line-height: 36px;
    -webkit-appearance: none;
    color: $color-black;
    margin-top: 5px;
  }
  
  .select-search__input::-webkit-search-decoration,
  .select-search__input::-webkit-search-cancel-button,
  .select-search__input::-webkit-search-results-button,
  .select-search__input::-webkit-search-results-decoration {
    -webkit-appearance: none;
  }
  
  .select-search__input:not([readonly]):focus {
    cursor: initial;
  }
  
  .select-search__select {
    background: $color-purewhite;
    box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.15);
    ul{padding: 0px 5px;;}
    min-height: 150px;
    overflow: auto;
    height: 100px;
  }
  
  .select-search__options {
    list-style: none;
  }
  
  .select-search__row:not(:first-child) {
    border-top: 1px solid $color-select-search;
  }
  
  .select-search__option {
    display: block;
    height: 36px;
    width: 100%;
    padding: 0px;
    background: $color-purewhite;
    border: none;
    outline: none;
    font-family: "Roboto-Regular";
    font-size: 11px;
    text-align: left;
    cursor: pointer;
  }
  
  .select-search--multiple .select-search__option {
    height: 48px;
  }
  
  .select-search__option.is-selected {
    color: $color-black;
  }
  
  .select-search__option.is-highlighted,
  .select-search__option:not(.is-selected):hover {
    background: rgba(57, 118, 235, 0.1);
  }
  
  .select-search__option.is-highlighted.is-selected,
  .select-search__option.is-selected:hover {
    color: $color-black;
  }
  
  .select-search__group-header {
    font-size: 10px;
    text-transform: uppercase;
    background: $color-select-search;
    padding: 8px 16px;
  }
  
  .select-search.is-disabled {
    opacity: 0.5;
  }
  
  .select-search.is-loading .select-search__value::after {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50' height='50' viewBox='0 0 50 50'%3E%3Cpath fill='%232F2D37' d='M25,5A20.14,20.14,0,0,1,45,22.88a2.51,2.51,0,0,0,2.49,2.26h0A2.52,2.52,0,0,0,50,22.33a25.14,25.14,0,0,0-50,0,2.52,2.52,0,0,0,2.5,2.81h0A2.51,2.51,0,0,0,5,22.88,20.14,20.14,0,0,1,25,5Z'%3E%3CanimateTransform attributeName='transform' type='rotate' from='0 25 25' to='360 25 25' dur='0.6s' repeatCount='indefinite'/%3E%3C/path%3E%3C/svg%3E");
    background-size: 11px;
  }
  
  .select-search:not(.is-disabled) .select-search__input {
    cursor: pointer;
  }
  
  .select-search--multiple {
    border-radius: 3px;
    overflow: hidden;
  }
  
  .select-search:not(.is-loading):not(.select-search--multiple)
    .select-search__value::after {
    background-image: url("data:image/svg+xml;base64,PHN2ZyBpZD0ic2VhcmNoIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIyMCIgdmlld0JveD0iMCAwIDIwIDIwIj4NCiAgPGcgaWQ9Ikdyb3VwXzQwMjIiIGRhdGEtbmFtZT0iR3JvdXAgNDAyMiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMCAwKSI+DQogICAgPHBhdGggaWQ9IlBhdGhfMjE2NSIgZGF0YS1uYW1lPSJQYXRoIDIxNjUiIGQ9Ik0xOS44NzgsMTguN2wtNS44MS01LjgxYTcuOTMzLDcuOTMzLDAsMSwwLTEuMTc4LDEuMTc4bDUuODEsNS44MWEuNDE3LjQxNywwLDAsMCwuNTg5LDBsLjU4OS0uNTg5QS40MTcuNDE3LDAsMCwwLDE5Ljg3OCwxOC43Wk03LjkxNywxNC4xNjdhNi4yNSw2LjI1LDAsMSwxLDYuMjUtNi4yNUE2LjI1Nyw2LjI1NywwLDAsMSw3LjkxNywxNC4xNjdaIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgwIDApIiBmaWxsPSIjYjRiZWNlIi8+DQogIDwvZz4NCjwvc3ZnPg0K");
    background-size: 14px;
    background-repeat: no-repeat;
  }
  
  .select-search--multiple .select-search__input {
    cursor: initial;
  }
  
  .select-search--multiple .select-search__input {
    border-radius: 3px 3px 0 0;
  }
  
  .select-search--multiple:not(.select-search--search) .select-search__input {
    cursor: default;
  }
  
  .select-search:not(.select-search--multiple) .select-search__input:hover {
    border-color: $color-puregray;
  }
  
  .select-search:not(.select-search--multiple) .select-search__select {
    position: absolute;
    z-index: 2;
    top: 44px;
    right: 0;
    left: 0;
    border-radius: 3px;
    overflow: auto;
    max-height: 360px;
  }
  
  .select-search--multiple .select-search__select {
    position: relative;
    overflow: auto;
    max-height: 260px;
    border-top: 1px solid $color-select-search;
    border-radius: 0 0 3px 3px;
  }