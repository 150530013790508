
@mixin center-align {
    display: flex;
    justify-content: center;
    align-items: center;
}
@mixin spacebetween-align {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
@mixin flex-start-align {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
@mixin flex-end-align {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
@mixin spacebetween-end {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

@mixin svg_fill_white {
    path {
        fill: $color-purewhite;
      }
      rect {
        fill: $color-purewhite;
      }
      ellipse {
        fill: $color-purewhite;
      }
      circle {
        fill: $color-purewhite;
      }
}

@mixin svg_fill_black {
    path {
        fill: $color-fill-svg;
      }
      rect {
        fill: $color-fill-svg;
      }
      ellipse {
        fill: $color-fill-svg;
      }
      circle {
        fill: $color-fill-svg;
      }
}