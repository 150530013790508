.t-holder {
  display      : flex;
  flex-wrap    : wrap;
  border       : 1px solid #ccc !important;
  margin-bottom: 5px;
  margin-top   : 5px;
  padding-left : 5px;
  border-radius: 4px;

  .t-checkcontainer {
    display    : flex;
    align-items: center;
    width      : 110px;

    .checkbox {
      width: 20px !important;
    }
  }
}