$color-blue: #3976eb;
html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}
html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

::-webkit-scrollbar {
  width: 10px;
  height: 5px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px $color-blue;
  border-radius: 3px;
}
 
::-webkit-scrollbar-thumb {
  background: $color-blue;
  border-radius: 3px;
}

