@import "../../../../assets/constant/color";
@import "../../../../assets/constant/mixin";
.Failurepage {
  .date-holder {
    margin-left: 20px;
    .filter-date {
      height: 40px;
      border-radius: 5px;
      border: 1px solid $color-puregray;
      padding: 10px;
      width: 120px;
    }
  }
  .options {
    margin-right: 0px;
    width: 125px;
    height: 45px;
    display: flex;
    align-items: center;
    padding-left: 15px;
    background-color: $color-fade-white;
    border-radius: 5px;
    justify-content: space-between;
    cursor: pointer;
    font-weight: 500;
    font-family: "Roboto-Regular";
    font-size: 12px;
    color: $color-black;
  }
  .options img {
    width: 13px;
    width: 15px;
    margin-right: 10px;
  }
  .submitbtn {
    background-color: $color-blue;
    height: 40px;
    margin-left: 20px;
    min-width: 80px;
  }
  .legend-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin: 50px 20px 40px 20px;
    .legend-holder {
      min-width: 380px;
      margin-top: 10px;
      width: 90%;
      .legend-top{
        align-items:flex-start;
      }
    }

    .control {
      display: flex;
      margin-top: 10px;
      width: 10%;
      .exportbtn {
        @include center-align;
        background-color: $color-blue;
        color: $color-purewhite;
        padding: 8px;
        border-radius: 4px;
        font-size: 13px;
        width: 100px;
        cursor: pointer;
        margin-left: 10px;
        height: 35px;
      }
      .exportbtn:hover {
        border: none;
        color: $color-purewhite;
        padding: 9px;
        background-color: $color-blue;
      }
    }
  }
}
