.textInput {
  width: 100%;
  height: 40px !important;
  font-size: 10px;
  padding: 0.4em 0.6em;
  margin-bottom: 5px;
  border: 1px solid #ccc !important;
  margin-top: 5px;
}

.textInput__label {
  font-weight: bold;
  font-size: 11px;
  padding: 0.2em 0.1em;
}

.desc{
  color: #988d8d;;
  font-size: 10px;
  padding-bottom: 10px;
}