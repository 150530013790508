@import "../../assets/constant/color";
.Pdf-container{
    padding: 10px;
    table {
        border-collapse: collapse;
        width: 100%;
      }
      
      table, th, td {
        border: 1px solid $color-black;
        
      }
      th, td {
        height: 30px;
        text-transform: capitalize;
      }
      th{
        font-family: 'Roboto-Medium';
       
      }
      td{
          padding: 5px;
          font-family: 'Roboto-Regular';
          font-size: 14px;
          word-break: break-all;
      }
      .title{
        font-family: 'Roboto-Medium';
      }
}
.graph-container{
  display: flex;
  justify-content:center;
  align-items: center;
  padding-bottom: 50px;
  flex-direction: column;
  #graphimg{
    width: 100%;
    height: auto;
  }
}
.loader {
  border: 3px solid #f3f3f3;
  border-radius: 50%;
  border-top: 3px solid $color-blue;
  width: 30px;
  height: 30px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
  margin: 50px;
}

@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}