@import "../../../../assets/constant/color";
@import "../../../../assets/constant/mixin";


.Generalpage{
    .filter-container {
        #sourcedd {
            width: 180px;
            margin-right: 15px;
        }
        #timeframedd{
            width: 120px;
            margin-right: 15px;
        }
      }
    .container-title{
      flex-wrap: wrap;
        .title {
          span{
            min-width:210px;
          }
        }
    }
    .options {
        margin-right: 0px;
        width: 110px;
        height: 45px;
        display: flex;
        align-items: center;
        padding-left: 15px;
        background-color: $color-fade-white;
        border-radius: 5px;
        justify-content: space-between;
        cursor: pointer;
        font-weight: 500;
        font-family: "Roboto-Regular";
        font-size: 12px;
        color: $color-black;
      }
      .searchop {
        padding-left: 15px !important;
      }
      .searchop img:first-child {
        filter: invert(1);
        width: 12px;
      }
      .options img {
        width: 13px;
        width: 15px;
        margin-right: 10px;
      }
      .legend-container{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        margin: 50px 20px 40px 20px;
        .legend-holder{
           min-width: 380px;
           margin-top: 10px;
        }
      
    .control{
        display: flex;
        margin-top: 10px;
      .submitbtn {
        background-color: $color-blue;
        height: 35px;
        min-width: 80px;
      }
      .date-holder {
        margin-right: 20px;
        .filter-date {
          height: 35px;
          border-radius: 5px;
          border: 1px solid $color-puregray;
          padding: 10px;
        }
      }
      .exportbtn {
        @include center-align;
        background-color: $color-blue;
        color: $color-purewhite;
        padding: 8px;
        border-radius: 4px;
        font-size: 13px;
        width: 100px;
        cursor: pointer;
        margin-left: 10px;
        height: 35px;
      }
      .exportbtn:hover {
        border: none;
        color: $color-purewhite;
        padding: 9px;
        background-color: $color-blue;
      }
    }
}

}
.download-container{
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
  align-items: center;
  .title{
    padding-left: 10px;
  }
.submitbtn{
    background-color:$color-blue;
    width: 105px;
  }
}