@import "../../assets/constant/color";
.swap{
    background-color: $color-blue;
    height: 45px;
    padding: 15px;
    border-radius: 3px;
    cursor: pointer;
    width: 110px;
    img{
        margin-right: 5px;
        height: 12px;
    }
    span{
        font-size: 13px;
        color:  $color-purewhite;
    }
}
.TrendDp{
    background-color: $color-trend;
    margin-right: 15px;
}