@import "../../../assets/constant/color";
.legend-bottom{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin:75px 40px 100px -30px;
    .square{
        margin-right: 20px;
        margin-top: 10px;
        .legendsquare{
            width: 17px;
            height: 15px;
            margin-right: 10px;
        }
        span{
            font-size: 14px;
            color: $color-black;
        }
    }
}
.legend-right{
    margin-left: 25px;
    min-width: 285px;
    width: 285px;
    height: 350px;
    flex-direction: column;
    display: flex;
    justify-content: space-around;
    .cirle{
        display: flex;
        justify-content: space-between;
        margin: 5px 10px;
        .legend-holder{
            display: flex;
            .legendcirle{
                width: 20px;
                height: 20px;
                border-radius: 20px;
                margin-right: 10px;
            }
            span{
                font-size: 14px;
                color: $color-black;
            }
        }
        span{
            font-size: 14px;
            color: $color-gray;
        }
    }
}
.legend-top{
    display: flex;
    justify-content: space-between;
    align-items: center;
    .rect{
        margin-right: 20px;
        display: flex;
        .legendrect{
            width: 25px;
            border-radius: 4px;
            height: 15px;
            margin-right: 10px;
        }
    }
}
.legend-top-wrap{
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    .rect{
        margin-right: 20px;
        display: flex;
        margin-bottom: 10px;
        align-items: center;
        .legendrect{
            width: 15px;
            border-radius: 4px;
            height: 15px;
            margin-right: 10px;
        }
        span{
            white-space: nowrap;
        }
    }
}