body {
  margin: 0;
  font-family: "Roboto-Regular";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f8f8fb;
}
@font-face {
  font-family: 'Roboto-Medium';
  src: url(./assets/fonts/Roboto-Medium.ttf) format('truetype');
}

@font-face {
    font-family: 'Roboto-Regular';
    font-weight: 900;
    src:  url(./assets/fonts/Roboto-Regular.ttf) format('truetype');
}