@import "../../assets/constant/color";
.Configuration-page {
  .quickaccess {
    padding: 40px 20px;
    .titlequick {
      text-transform: uppercase;
      font-size: 12px;
      color: $color-gray;
      font-weight: 500;
      font-family: "Roboto-Regular";
    }
  }
}
