@import "../../assets/constant/color";
.detailbtn {
  width: 80px;
  height: 35px;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 500;
  font-family: "Roboto-Regular";
  color: white;
  font-size: 13px;
  margin: 0px 10px;
}
.greenclr {
  background-color: $color-green !important;
}
.clearbtncolor {
  background-color: $color-gray;
}
.deletebtn {
  color: $color-red-btn;
  background-color: $color-redshade;
  width: 130px;
}
.deletebtn img {
  margin-right: 5px;
}
.cancelbtn {
  color: $color-black;
  background-color: $color-purewhite;
  width: 100px;
  border: 1px solid $color-black;
}

.addbtn {
  width: 100%;
  height: 45px;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 500;
  font-family: "Roboto-Regular";
  color: $color-purewhite;
  font-size: 13px;
}
