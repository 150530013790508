$color-blue: #3976eb;
$color-darkblue: #2855b4;
$color-trend:#11192f;
$color-green: #35b27d;
$color-dash-green: #0c8559;
$color-dash-red: #f6726e;
$color-dash-yellow: #c9892e;
$color-white: #f8f8fb;
$color-purewhite: #ffffff;
$color-fade-white: #f1f5f7;
$color-fade2-white: #f1f1f1;
$color-title-white:#f1f0ef;
$color-fade3-white:#f5f3f3;
$color-red: #f53c56;
$color-red-btn: #ed4c4c;
$color-tab-br:#e8e8e8;
$color-gray: #9ca2b4;
$color-lightgray: #a6adbb;
$color-puregray:#cccccc;
$color-black: #172b4d;
$color-redshade: #fef8f8;
$color-fill-svg: #40536c;
$color-notify-orange: #ff9f00;
$color-pureblack: #000000;
$color-defalut-tooltip:#333333;
$color-pagination:#8898aa;
$color-select-search:#eeeeee;
$color-deactive:#8591a0;
$color-profile:#ededed;